/* style.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  .prism-table td svg {
    @apply mx-auto h-6;
  }

  .prism-table td,
  .prism-table th {
    padding: 0.5rem;
    border-width: 0.5px;
  }

  .prism-btn-red {
    @apply prism-btn !text-red-600 outline-red-600 hover:bg-red-100 hover:outline-red-400
    active:bg-red-200 focus:shadow-red-400 focus:shadow;
  }

  .react-select-reset input {
    @apply focus:shadow-none focus:ring-0 focus:outline-none;
  }
}

.react-select-container div {
  @apply leading-7;
}

/* style for react select */
.react-select__control {
  border-color: hsl(0deg 0% 70%) !important;
  border-width: 1px !important;
  box-shadow: none !important;
}

.react-select__control:focus-within {
  border-color: #2372b8 !important;
}

.react-select__input:focus {
  --tw-ring-shadow: none !important;
  --tw-ring-color: none !important;
}

/* style for quill editor */
.ql-bubble .ql-tooltip {
  z-index: 5;
}

.ql-editor {
  font-size: 1rem;
}

.react-datepicker__header {
  background-color: #fff;
}

.react-datepicker__navigation-icon::before {
  border-color: #2372b8;
  top: 16px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #2372b8;
  font-size: 1rem;
  margin: 4px 4px 8px;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #003468;
  color: #fff;
  border-radius: 50%;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #003468;
}

.react-datepicker__day--selected:hover {
  background-color: #003468;
  color: #fff;
  border-radius: 50%;
}

.react-datepicker__day:hover {
  border-radius: 50%;
}
